import { Store } from "vuex";
import { createRouter, createWebHistory } from "vue-router";
import { version } from "/@/modules/version";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

import { RootState } from "../store";
import { routes } from "./routes";

export function creatingRouter({
  store,
  mock,
}: {
  store: Store<RootState>;
  mock?: boolean;
}) {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (mock) {
      next();
      return;
    }

    const currentVersion = version;

    const { data: serverVersion } = await axios
      .get("/api/v1/version")
      .catch((e) => errorHandle(e));

    if (currentVersion < serverVersion) {
      window.alert("アップデートがあるためページを更新します");
      window.location.reload();
      next({ path: from.fullPath });
    }

    let user = store.state.user.user;

    if (!user) {
      if (await store.dispatch("user/GET_USER")) {
        user = store.state.user.user;
      }
    }

    if (!to.name) {
      return;
    }

    if (!to.meta?.dontNeedLogin && !user) {
      next({ name: "SignIn" });
    } else if (to.name == "SignIn" && !!user) {
      next({ name: "UsersIndex" });
    } else {
      next();
    }
  });

  return router;
}
