<template>
  <div id="app" class="h-full">
    <!--  PageHeader
      v-if="isIndexPages"
      ref="header"
      :user="user"
      @logout="logout"
      class="rounde-0 sticky top-0 left-0 w-full"
    /-->
    <div
      class="header flex items-center cursor-pointer"
      style="border-bottom: 1px solid #ccc"
      @click="goto({ name: 'Index' })"
    >
      <img :src="Logo" alt="ロゴ" class="w-16" />
      <h1 class="mx-3 text-xl font-bold text-gray-700 dark:text-white">
        株式会社S.R.system
      </h1>
    </div>

    <div
      class="router-view p-4 mx-3"
      :style="{
        height: isIndexPages ? `calc(100% - ${headerHeight})` : `100%`,
        backgroundImage: `url(${DarkLogo})`,
        backgroundPosition: 'top right',
        backgroundSize: isMobile ? '100px auto' : '200px auto',
        backgroundRepeat: 'no-repeat',
        'backdrop-filter': 'blur(5px)',
      }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useAuthorize, useRouterUtil, useMqUtils } from "/@/vue/composables";
import Logo from "../../../../public/logo_without_name.png";
import DarkLogo from "../../../../public/logo_without_name_dark.png";

const { isMobile } = useMqUtils();

const { currentRouteName, currentRouteNeedLogin, goto } = useRouterUtil();

const isIndexPages = computed(() => {
  if (!currentRouteName.value) return false;

  return !currentRouteNeedLogin.value;
});

const headerHeight = "92.16px";
const { logout } = useAuthorize();
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  font-family: "Noto Sans JP", "メイリオ", "Meiryo", sans-serif;

  .header {
    @apply py-2;
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
