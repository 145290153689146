<template>
  <div class="company-info">
    <div class="mb-5">
      <h1>会社概要</h1>
    </div>

    <ul>
      <li><span class="th">社名</span><span>株式会社S.R.system</span></li>
      <li>
        <span class="th">所在地</span
        ><span>石川県金沢市横川2丁目123番地ルポゼ・Ｉ号</span>
      </li>
      <li>
        <span class="th">代表電話</span
        ><a href="tel:08080879980">080-8087-9980</a>
      </li>
      <li><span class="th">代表者</span><span>鈴木清俊、山内亮汰</span></li>
      <li><span class="th">資本金</span><span>100万</span></li>
      <li>
        <span class="th">事業内容</span
        ><span>警備業、飲食、古物商、車販売及びレンタカー事業</span>
      </li>
    </ul>
  </div>
</template>

<script setup></script>

<style scoped>
.company-info {
  @apply mx-auto p-5 dark:bg-gray-950;
  transition: background-color 0.3s;
  max-width: 800px;
  background: none;
}
h1 {
  @apply mb-5 text-4xl font-bold text-gray-900 dark:text-white;
}
p {
  @apply mb-5 text-gray-700 dark:text-gray-300;
}
ul {
  @apply list-none p-0;
  margin-top: 5rem;
}
li {
  @apply flex justify-start items-center py-6 px-5 border-b border-gray-300 dark:border-gray-600;
  color: #333;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;

    padding: 10px;
  }
}
li:first-of-type {
  @apply border-t;
}
.th {
  @apply text-gray-400 dark:text-gray-300;
  width: 200px;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-bottom: 1rem;
  }
}
span,
a {
  @apply dark:text-gray-300;
}
.toggle-button {
  @apply mb-5 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg;
  transition: background-color 0.3s;
}
</style>
