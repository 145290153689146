import Index from "./Index.vue";
import NotFound from "./NotFound.vue";

export const routes = [
  {
    path: "/",
    name: "Index",
    title: "Top",
    meta: {
      dontNeedLogin: true,
    },
    component: Index,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    title: "Not Found",
    meta: {
      dontNeedLogin: true,
    },
    component: NotFound,
  },
];
