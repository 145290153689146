import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import { userState } from "./user";
import type { UserStoreState } from "./user";

export type RootState = {
  user: UserStoreState;
};

export function createStoreWith() {
  const s = {
    modules: {
      user: userState,
    },
  };

  return createStore(s);
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}
