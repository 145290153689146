import Axios from "axios";
import { version } from "./version";

export const axios = Axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-Version": version,
  },
  withCredentials: true,
});

export const fetcher = async (url: string) => {
  return axios.get(url).then((res) => {
    console.log("get " + url, res);
    return res.data;
  });
};

// nullfy
export function undefineKeyToNull(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      obj[key] = null;
    }
  });

  return obj;
}

export function deepUndefineKeyToNull(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      obj[key] = null;
    } else if (typeof obj[key] === "object") {
      deepUndefineKeyToNull(obj[key]);
    }
  });

  return obj;
}

export function nullKeyToUndefine(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = undefined;
    }
  });

  return obj;
}

export function deepNullKeyToUndefined(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = undefined;
    } else if (typeof obj[key] === "object") {
      deepNullKeyToUndefined(obj[key]);
    }
  });

  return obj;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("undefineKeyToNull", () => {
    expect(undefineKeyToNull({ a: 1, b: undefined })).toStrictEqual({
      a: 1,
      b: null,
    });
  });
}
